// https://forum.builder.io/t/how-to-build-dynamic-resource-pages-with-builder-and-gatsbyjs/515

import { BuilderComponent, BuilderContent } from '@builder.io/react'
import { getCognitoId, getVisitorId } from 'components/util/UserId'
import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import PageLayout from '../../components/ui-builder/layouts/PageLayout'

import '../../builder-settings'

// const defaultDescription = 'Blog Description'

const defaultTitle = 'Blog Title'

function BlogTemplate({ data }: any) {
  const [_visitorId, setVisitorId] = useState('')
  const [_cognitoId, setCognitoId] = useState('')
  const content = data?.allBuilderModels.blogArticle[0]?.content
  const articleData = content.data
  const headerWrapperBackgroundColor = data?.allBuilderModels.blogArticle[0]?.data?.headerWrapperBackgroundColor

  useEffect(() => {
    const callIdsForGtag = async () => {
      setVisitorId(await getVisitorId())
      setCognitoId(await getCognitoId())
    }
    callIdsForGtag()
  }, [])
  return (
    <BuilderContent model="blog-article" content={content}>
      {blogContent => {
        console.log('BlogTemplate:: Template on change data', blogContent)

        return (
          <PageLayout
            headerIdentifier={blogContent?.header?.id || articleData?.header?.id}
            footerIdentifier={blogContent?.footer?.id || articleData?.footer?.id}
            bannerEnable={blogContent?.enableTopBanner || articleData?.enableTopBanner}
            activeBannerIdentifier={blogContent?.activeBanner?.id || articleData?.activeBanner?.id}
            title={blogContent?.title || articleData?.title || defaultTitle}
            headerWrapperBackgroundColor={headerWrapperBackgroundColor}
          >
            <Helmet>
              {/* meta tags for page */}
              <title>{blogContent?.title || articleData?.title || defaultTitle}</title>
              <meta property="og:description" content={articleData?.ogDescription || blogContent?.description || ''} />
              <meta name="description" content={blogContent?.description || articleData?.ogDescription || ''} />
              <meta property="og:site_name" content={articleData?.siteName || 'Different Dog'} />
              <meta property="og:title" content={articleData?.ogTitle || blogContent?.title || defaultTitle} />
              <meta property="og:type" content={articleData?.ogType || 'website'} />
              <meta
                property="og:url"
                content={
                  (articleData?.ogUrl || blogContent?.canonical || articleData?.url)?.substr(0, 1) === '/'
                    ? (articleData?.ogUrl || blogContent?.canonical || articleData?.url).replace(
                        '/',
                        'https://www.differentdog.com/'
                      )
                    : articleData?.ogUrl || blogContent?.canonical || articleData.url
                }
              />
              (articleData?.ogImage ) &&
              <meta property="og:image" content={articleData.ogImage} />
              {/* <meta property="og:image" content={blogContent?.ogImage || articleData?.ogImage} /> */}
              {blogContent.noIndex ? <meta name="robots" content="noindex" /> : ''}
              <link
                rel="canonical"
                href={
                  (blogContent?.canonical || articleData?.url)?.substr(0, 1) === '/'
                    ? (blogContent?.canonical || articleData?.url).replace('/', 'https://www.differentdog.com/')
                    : blogContent?.canonical || articleData?.url
                }
              />
              {/* meta tags ends for page */}
              {/* <!-- Gtag script --> */}
              <script>{`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
              `}</script>
              {/* <!-- End Gtag script --> */}
              {/* Plausible Analytics script */}
              {/* <script defer data-api="/api/event" data-domain="differentdog.com" src="/js/script.js"></script> */}
            </Helmet>
            {/* <h2>Blog template</h2> */}
            {/** name of the model is landing page, change it if you decided to build*/}
            <BuilderComponent model="blog-article" content={content} />
          </PageLayout>
        )
      }}
    </BuilderContent>
  )
}

export default BlogTemplate

export const BlogTemplateQuery = graphql`
  query ($path: String!) {
    allBuilderModels {
      blogArticle(target: { urlPath: $path }, options: { cachebust: true }, limit: 50) {
        content
        data {
          headerWrapperBackgroundColor
        }
      }
    }
  }
`
