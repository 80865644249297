import React from 'react'
import BlogArticle from './BlogArticle'
import Page from './Page'
import Recipe from './Recipe'

type TemplateMapType = {
  [key: string]: any
}

const TemplateMap: TemplateMapType = {
  page: <Page />,
  blogArticle: <BlogArticle />,
  recipe: <Recipe />
}

export { TemplateMap }
